



































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import ImgView from '@/components/ImgView/index.vue'
import RemarkModal from '@/views/common/remark-modal.vue'
import { getDicts, getDictName } from '@/utils/dict'
import { getExportExcel } from '@/utils/export'
import { CommonModule } from '@/store/modules/common'
import { DatePicker } from 'element-ui'
Vue.use(DatePicker)

@Component({
  components: {
    Table,
    Pagination,
    ImgView,
    RemarkModal
  }
})
export default class VisitorList extends Vue {
  $constants: any
  // 字典数据
  // 开门方式
  openDoorWayDicts: any = []
  // 人员类型
  personTypeDicts: any = []
  searchModel = {
    key: 'enterTime',
    value: null
  }
  openDoorType = ''
  enterDeviceId = ''
  listLoading = false
  tableHeader = [
    {
      key: 'faceImg',
      name: '面部识别',
      width: '20%',
      isSpecial: true
    },
    {
      key: 'remark',
      name: '备注',
      width: '20%',
      remark: true
    },
    {
      key: 'snapTime',
      name: '进入时间',
      width: '20%'
    },
    {
      key: 'openType',
      name: '开门方式',
      width: '20%',
      isSpecial: true
    },
    {
      key: 'devGroupName',
      name: '位置',
      width: '20%'
    }
  ]
  defaultFemaleIcon = 'this.src="' + require('@/assets/images/female.png') + '"'
  defaultmaleIcon = 'this.src="' + require('@/assets/images/male.png') + '"'
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 10
  }
  imgUrl = ''
  // 获取门禁数据
  get doorGroups() {
    return CommonModule.doorGroups
  }

  async beforeCreate() {
    // 获取相关字典
    // 开门方式
    this.openDoorWayDicts = await getDicts('FACE_LOG_OPEN_TYPE')
    this.personTypeDicts = await getDicts('FACE_LOG_PERSON_TYPE')
    CommonModule.GetDoorGroups()
  }

  mounted() {
    if (this.$route.query.id) {
      this.getVisitorList()
    } else {
      this.$router.push('/property/staff')
    }
  }

  // 获取访客列表
  getVisitorList() {
    this.listLoading = true
    this.$api.visitor
      .getVisitorList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        personId: this.$route.query.id,
        personType: 5,
        openDoorType: this.openDoorType !== '' ? Number(this.openDoorType) : '',
        enterDeviceId:
          this.enterDeviceId !== '' ? Number(this.enterDeviceId) : '',
        snapTimeStart: this.searchModel.value
          ? (this.searchModel.value as any)[0]
          : '',
        snapTimeEnd: this.searchModel.value
          ? (this.searchModel.value as any)[1]
          : ''
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        this.tableData = data.data
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  // 批量导出
  doExport() {
    // TODO
    const p = {
      exportType: 'visitor',
      personId: this.$route.query.id,
      personType: 5,
      openDoorType: this.openDoorType !== '' ? Number(this.openDoorType) : '',
      enterDeviceId:
        this.enterDeviceId !== '' ? Number(this.enterDeviceId) : '',
      snapTimeStart: this.searchModel.value
        ? (this.searchModel.value as any)[0]
        : '',
      snapTimeEnd: this.searchModel.value
        ? (this.searchModel.value as any)[1]
        : ''
    }
    getExportExcel(p, '进出记录导出.xlsx')
  }

  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }
}
